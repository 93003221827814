<template>
  <loading-dialog :loading="$apollo.loading">
    <whatsapp-credentials :account="adminAccount" @refresh="refreshAccount" />
    <email-config :account="adminAccount" @refresh="refreshAccount" />
    <sms-credentials :account="adminAccount" @refresh="refreshAccount" />
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'
export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue'),
    EmailConfig: () => import('@/modules/accounts/components/edit/EmailConfig.vue'),
    SmsCredentials: () => import('@/modules/accounts/components/edit/SmsCredentials.vue'),
    WhatsappCredentials: () => import('@/modules/accounts/components/edit/WhatsappCredentials.vue')
  },
  data: () => ({
    accountId: ''
  }),
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminAccount }) {
        console.log(adminAccount)
        return adminAccount
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    refreshAccount() {
      this.$apollo.queries.adminAccount.refresh()
    }
  }
}
</script>
